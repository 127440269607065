<template>
  <div class="home">
    <Head></Head>
    <swiper :options="swiperOption">
      <swiper-slide>
        <div class="swiperFirstWrap">
          <div class="swiperFirst">
            <img
              class="leftTop scaleTop"
              src="@/assets/images/home/leftTop.png"
              alt
            />
            <img
              class="leftBot scaleBottom"
              src="@/assets/images/home/leftBot.png"
              alt
            />
            <img
              class="rightTop scaleBottom"
              src="@/assets/images/home/rightTop.png"
              alt
            />
            <img
              class="rightBot scaleTop"
              src="@/assets/images/home/rightBot.png"
              alt
            />
            <p class="fadeInUp">强基层，为天使插上翅膀</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="swiperSecondWrap">
          <div class="swiperSecond">
            <p>通过建设适合基层的医疗互联网</p>
            <p>生态体系提升基层医疗的竞争力</p>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="main">
      <div class="topWrap">
        <dl>
          <router-link to="/solutionsDetail/CloudClinic">
            <dt
              class
              v-on:mouseover="changeActive($event)"
              v-on:mouseout="removeActive($event)"
            >
              <div class="imgWrap">
                <img src="@/assets/images/home/CloudClinic.png" />
                <div class="close-overlay"></div>
              </div>
              <img
                class="logoImg"
                src="@/assets/images/home/CloudClinicLogo.png"
                width="243"
              />
            </dt>
            <dd
              v-on:mouseover="changeHover($event)"
              v-on:mouseout="removeHover($event)"
            >
              <div>
                <strong>致医云诊室</strong>
                致力于为基层医生提供一站式全流程工作台服务。不仅包括看病开方、药房管理、统计报表等日常管理功能，而且提供患者管理、医生在线学习等医患沟通、医医交流的平台，帮助基层医生通过互联网平台大幅提升诊所经营效率，全面提升基层医疗竞争力。
              </div>

              <span class="moreBtn">了解更多</span>
            </dd>
          </router-link>
        </dl>
        <dl>
          <router-link to="/solutionsDetail/CloudPharmacy">
            <dt
              class
              v-on:mouseover="changeActive($event)"
              v-on:mouseout="removeActive($event)"
            >
              <div class="imgWrap">
                <img src="@/assets/images/home/CloudPharmacy.png" />
                <div class="close-overlay"></div>
              </div>
              <img
                class="logoImg"
                src="@/assets/images/home/CloudPharmacyLogo.png"
                width="207"
              />
            </dt>
            <dd
              v-on:mouseover="changeHover($event)"
              v-on:mouseout="removeHover($event)"
            >
              <div>
                <strong>致医云药房</strong>
                专注为单体药店（含加盟连锁门店）、零售连锁药店等药品零售企业提供一站式新零售SaaS解决方案。通过构建POS收银、GSP管理、线上微商城、精准营销服务、智能辅助决策、员工协同服务、在线问诊、健康服务、线上直采等9大业务平台，全方位赋能药店。
              </div>

              <span class="moreBtn">了解更多</span>
            </dd>
          </router-link>
        </dl>
      </div>
      <div class="middleWrap">
        <dl>
          <router-link to="/solutionsDetail/CloudHIS">
            <dt
              class
              v-on:mouseover="changeActive($event)"
              v-on:mouseout="removeActive($event)"
            >
              <div class="imgWrap">
                <img src="@/assets/images/home/CloudHIS.png" />
                <div class="close-overlay"></div>
              </div>
              <img
                class="logoImg"
                src="@/assets/images/home/CloudHISLogo.png"
                width="147"
              />
            </dt>
            <dd
              v-on:mouseover="changeHover($event)"
              v-on:mouseout="removeHover($event)"
            >
              <div>
                <strong>致医云HIS</strong>
                定位于为中高端基层医疗机构提供全方位诊疗管理系统解决方案。包括医疗机构常用的门店管理，员工管理，医生排班，患者管理，库存管理，费用管理等一系列常用功能，使基层医院经营实现精细化、智能化管理。
              </div>

              <span class="moreBtn">了解更多</span>
            </dd>
          </router-link>
        </dl>
        <dl>
          <router-link to="/solutionsDetail/Mall">
            <dt
              class
              v-on:mouseover="changeActive($event)"
              v-on:mouseout="removeActive($event)"
            >
              <div class="imgWrap">
                <img src="@/assets/images/home/mall.jpg" />
                <div class="close-overlay"></div>
              </div>
              <img
                class="logoImg"
                src="@/assets/images/home/mallLogo.png"
                width="188"
              />
            </dt>
            <dd
              v-on:mouseover="changeHover($event)"
              v-on:mouseout="removeHover($event)"
            >
              <div>
                <strong>药发采</strong>
                是面向基层的一站式药品批发采购商城平台，通过合作多家优质商业，结合朗致集团独有特色品种，运用移动互联网技术，为基层医疗机构提供便捷、高性价比的药品信息服务，优化基层医药行业供应链营销过程的信息流、物流、资金流。
              </div>

              <span class="moreBtn">了解更多</span>
            </dd>
          </router-link>
        </dl>
        <dl>
          <router-link to="/solutionsDetail/HealthManagement">
            <dt
              class
              v-on:mouseover="changeActive($event)"
              v-on:mouseout="removeActive($event)"
            >
              <div class="imgWrap">
                <img src="@/assets/images/home/HealthManagement.png" />
                <div class="close-overlay"></div>
              </div>
              <img
                class="logoImg"
                src="@/assets/images/home/HealthManagementLogo.png"
                width="243"
              />
            </dt>
            <dd
              v-on:mouseover="changeHover($event)"
              v-on:mouseout="removeHover($event)"
            >
              <div>
                <strong>致医健康管理</strong>
                致力于全面提升基层患者的就诊体验。功能包含预约挂号、服药提醒、患者随访及标签化管理等。致医智能医疗设备不仅能检测健康指标，还能通过与医生、家人共享数据实现健康管理，享受“智能家庭医生”体验。
              </div>
              <span class="moreBtn">了解更多</span>
            </dd>
          </router-link>
        </dl>
      </div>
      <div class="bottomWrap">
        <h3>官方代理加盟</h3>
        <div class="businessWrap">
          <img src="@/assets/images/home/BusinessInvitation.png" />
          <div class="businessText">
            <p>官方代理火热招募中，期待您的加盟！</p>
            <!-- <router-link to="/Join"> -->
            <router-link :to="{ name: 'Join', params: { nameId: 'name2' } }">
              <span>了解详情</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<style lang="scss">
.swiper-container {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 2px;
    opacity: 0.2;
    margin: 0 18px 8px 0 !important;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #fff !important;
    opacity: 1 !important;
  }
}
</style>
<style lang="scss" scoped>
.home {
  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .fadeInUp {
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @keyframes scaleTop {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .scaleTop {
    animation: scaleTop 2s infinite;
  }
  @keyframes scaleBottom {
    0% {
      transform: translateY(8px);
    }
    50% {
      transform: translateY(16px);
    }
    100% {
      transform: translateY(8px);
    }
  }
  .scaleBottom {
    animation: scaleBottom 2s infinite;
  }
  .swiperFirstWrap,
  .swiperSecondWrap {
    width: 100%;
    height: 550px;
  }
  .swiperFirstWrap {
    background: linear-gradient(
      161deg,
      rgba(0, 82, 217, 1) 0%,
      rgba(34, 19, 222, 1) 51%,
      rgba(43, 30, 205, 1) 100%
    );
    .swiperFirst {
      width: 79.17%;
      max-width: 1140px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      p {
        font-size: 70px;
        color: #fff;
      }
      .leftTop {
        position: absolute;
        left: 54px;
        bottom: 108px;
        // opacity: 0.5;
      }
      .leftBot {
        position: absolute;
        left: 0;
        bottom: 78px;
        // opacity: 0.5;
      }
      .rightTop {
        position: absolute;
        right: 0;
        top: 161px;
        // opacity: 0.3;
      }
      .rightBot {
        position: absolute;
        right: 0;
        top: 195px;
        // opacity: 0.3;
      }
    }
  }
  .swiperSecondWrap {
    background: #2213de url('../assets/images/home/banner2.png') no-repeat
      center 0;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiperSecond {
      p {
        font-size: 60px;
        line-height: 86px;
        color: #fff;
      }
    }
  }

  .main {
    width: 79.17%;
    max-width: 1140px;
    margin: 0 auto;
    @keyframes bounceIn {
      0% {
        // height: 0;
        opacity: 0;
      }
      100% {
        // height: 100%;
        // transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes bounceOut {
      0% {
        // height: 100%;
        opacity: 1;
      }
      100% {
        // height: 0;
        opacity: 0;
      }
    }

    dl {
      background: #fff;
      text-align: left;
      transition: all 0.3s ease-in-out 0s;
      position: relative;
      dt {
        justify-content: center;
        align-items: center;
        display: flex;
        .imgWrap {
          position: relative;
          z-index: 1;
          img {
            width: 100%;
          }
          .close-overlay {
            opacity: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 100;
            width: 100%;
            height: 100%;
            background-color: #2213de;
            border-radius: 4px;
          }
        }

        .logoImg {
          position: absolute;
          z-index: 2;
        }
      }
      dt.activeIn {
        .close-overlay {
          animation: bounceIn 0.5s forwards;
          -webkit-animation: bounceIn 0.5s forwards;
        }
      }
      dt.activeOut {
        .close-overlay {
          animation: bounceOut 0.5s forwards;
          -webkit-animation: bounceOut 0.5s forwards;
        }
      }
      dd {
        font-size: 14px;
        color: #666;
        padding: 20px 20px 72px 20px;
        line-height: 28px;
        text-align: justify;
        strong {
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }
        .moreBtn {
          display: inline-block;
          padding: 2px 25px;
          border: 1px solid #2213de;
          font-size: 12px;
          color: #2213de;
          cursor: pointer;
          position: absolute;
          bottom: 20px;
        }
        .moreBtn:hover {
          background: #2213de;
          color: #fff;
        }
      }
    }
    // dl:hover {
    .dlHover {
      box-shadow: 2px 4px 30px 0 rgba(0, 0, 0, 0.1);
      margin-top: -2px;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
      padding-bottom: 2px;
    }
    .topWrap,
    .middleWrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .topWrap {
      margin: 30px 0;
      dl {
        width: 48%;
        // max-width: 555px;
      }
    }
    .middleWrap {
      dl {
        width: 30.78%;
        max-width: 350px;
      }
    }
    .bottomWrap {
      h3 {
        font-size: 24px;
        color: #333;
        text-align: left;
        margin: 40px 0 30px 0;
        font-weight: bold;
      }
      .businessWrap {
        justify-content: center;
        align-items: center;
        display: flex;
        img {
          position: relative;
          z-index: 1;
          width: 100%;
        }
        .businessText {
          position: absolute;
          z-index: 2;
          p {
            height: 50px;
            font-size: 32px;
            color: rgba(255, 255, 255, 1);
          }
          span {
            display: inline-block;
            width: 160px;
            height: 44px;
            line-height: 44px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            font-size: 14px;
            color: #2213de;
            margin-top: 40px;
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .swiperFirstWrap,
    .swiperSecondWrap {
      height: 320px;
    }
    .swiperFirstWrap {
      .swiperFirst {
        p {
          font-size: 50px;
        }

        .leftTop {
          left: 27px;
          bottom: 54px;
        }
        .leftBot {
          bottom: 39px;
        }
        .rightTop {
          top: 80px;
        }
        .rightBot {
          top: 97px;
        }
      }
    }
    .swiperSecondWrap {
      .swiperSecond {
        p {
          font-size: 50px;
        }
      }
    }
    .main {
      width: 94%;
    }
  }
  @media screen and (max-width: 768px) {
    .swiperFirstWrap,
    .swiperSecondWrap {
      height: 260px;
    }
    .swiperFirstWrap {
      .swiperFirst {
        p {
          font-size: 36px;
        }

        .leftTop {
          left: 27px;
          bottom: 54px;
        }
        .leftBot {
          bottom: 39px;
        }
        .rightTop {
          top: 80px;
        }
        .rightBot {
          top: 97px;
        }
      }
    }
    .swiperSecondWrap {
      .swiperSecond {
        p {
          font-size: 30px;
          line-height: 46px;
        }
      }
    }
    .main {
      width: 94%;
      .topWrap {
        display: block;
        dl {
          width: 100%;
        }
      }
      .middleWrap {
        display: block;
        dl {
          width: 100%;
          max-width: 100%;
        }
      }
      .bottomWrap {
        .businessWrap {
          .businessText {
            p {
              font-size: 26px;
            }
            span {
              width: 160px;
              height: 40px;
              line-height: 40px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'

export default {
  name: 'home',
  components: {
    Head,
    Foot
  },
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false // 手动切换之后继续自动轮播
        },
        loop: true
      }
    }
  },
  methods: {
    // 鼠标移入加入class
    changeActive ($event) {
      $event.currentTarget.className = 'activeIn'
    },
    removeActive ($event) {
      $event.currentTarget.className = 'activeOut'
    },
    changeHover ($event) {
      $event.currentTarget.parentElement.parentElement.className = 'dlHover'
    },
    removeHover ($event) {
      $event.currentTarget.parentElement.parentElement.className = ''
    }
  },
  computed: {},
  mounted () {}
}
</script>
