<template>
  <div :class="bgcolor === '0' ? 'foot' : 'foot footBg'">
    <div class="footWrap">
      <p>
        北京致医健康信息技术有限公司 | 联系我们：400-620-9299 |
        地址：北京经济技术开发区地泽北街1号
      </p>
      <p>
        Copyright © {{ year }} 致医健康 All Rights Reserved |
        <a href="http://beian.miit.gov.cn/" target="_blank" class="icp"
          >ICP备案号码：京ICP备17003706号-4</a
        >
      </p>
      <p>
        互联网药品信息服务资格证书编号：(京)-经营性-2017-012 |
        医疗器械网络交易服务第三方平台备案凭证编号:
        (京）网械平台备字（2019）第00005号
      </p>
      <p>网络食品交易第三方平台提供者备案号：京食药网食备201910048</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  props: {
    bgcolor: {
      type: String,
      default: '0' // 背景色：0为灰色，1为白色
    }
  },
  methods: {},
  created () { }
}
</script>

<style lang="scss" scoped>
.footBg {
  background: #fff;
}
.foot {
  padding: 90px 0 0 0;
  .footWrap {
    width: 79.17%;
    max-width: 1140px;
    border-top: 2px solid #eee;
    text-align: center;
    padding: 40px;
    margin: 0 auto;
    box-sizing: border-box;
    p {
      font-size: 14px;
      color: #999;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
  }
  .icp {
    color: #999;
  }
}
@media screen and (max-width: 1280px) {
  .foot {
    .footWrap {
      width: 90%;
    }
  }
}
@media screen and (max-width: 1024px) {
  .foot {
    padding: 40px 0 0 0;
    .footWrap {
      width: 94%;
    }
  }
  .foot .footWrap p {
    line-height: 26px;
  }
}
</style>
